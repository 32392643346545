export function waitForElement(selector) {
    return new Promise(function (resolve) {
        var element = document.querySelector(selector);
        if (element) {
            return resolve(element);
        }
        var observer = new MutationObserver(function (_) {
            var element = document.querySelector(selector);
            if (element) {
                observer.disconnect();
                resolve(element);
            }
        });
        observer.observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    });
}
